import React from 'react';
import { bool, node } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import { Form, PrimaryButton, FieldTextInput, FieldSelect } from '../../../components';

import css from './SignupForm.module.css';
import { hearAboutOptions } from '../utils';

const SignupFormComponent = props => {
  // Extract the current language from URL parameters.
  const { lang: currentLanguage } = useParams();

  // Destructure onSubmit from props and create an enhanced onSubmit handler.
  const { onSubmit, ...rest } = props;
  const handleFormSubmit = values => {
    // Attach the current language to the submitted values.
    const enrichedValues = {
      ...values,
      language: currentLanguage,
    };
    return onSubmit(enrichedValues);
  };

  return (
    <FinalForm
      {...rest}
      onSubmit={handleFormSubmit}
      mutators={{ ...arrayMutators }}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          formId,
          handleSubmit,
          inProgress,
          invalid,
          intl,
          termsAndConditions,
        } = fieldRenderProps;

        // Email validation
        const emailRequired = validators.required(
          intl.formatMessage({ id: 'SignupForm.emailRequired' })
        );
        const emailValid = validators.emailFormatValid(
          intl.formatMessage({ id: 'SignupForm.emailInvalid' })
        );

        // Password validation
        const passwordRequiredMessage = intl.formatMessage({
          id: 'SignupForm.passwordRequired',
        });
        const passwordMinLengthMessage = intl.formatMessage(
          { id: 'SignupForm.passwordTooShort' },
          { minLength: validators.PASSWORD_MIN_LENGTH }
        );
        const passwordMaxLengthMessage = intl.formatMessage(
          { id: 'SignupForm.passwordTooLong' },
          { maxLength: validators.PASSWORD_MAX_LENGTH }
        );
        const passwordMinLength = validators.minLength(
          passwordMinLengthMessage,
          validators.PASSWORD_MIN_LENGTH
        );
        const passwordMaxLength = validators.maxLength(
          passwordMaxLengthMessage,
          validators.PASSWORD_MAX_LENGTH
        );
        const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
        const passwordValidators = validators.composeValidators(
          passwordRequired,
          passwordMinLength,
          passwordMaxLength
        );

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = inProgress;
        const submitDisabled = invalid || submitInProgress;

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <div>
              <FieldTextInput
                type="email"
                id={formId ? `${formId}.email` : 'email'}
                name="email"
                autoComplete="email"
                label={intl.formatMessage({ id: 'SignupForm.emailLabel' })}
                placeholder={intl.formatMessage({ id: 'SignupForm.emailPlaceholder' })}
                validate={validators.composeValidators(emailRequired, emailValid)}
              />
              <div className={css.name}>
                <FieldTextInput
                  className={css.firstNameRoot}
                  type="text"
                  id={formId ? `${formId}.fname` : 'fname'}
                  name="fname"
                  autoComplete="given-name"
                  label={intl.formatMessage({ id: 'SignupForm.firstNameLabel' })}
                  placeholder={intl.formatMessage({ id: 'SignupForm.firstNamePlaceholder' })}
                  validate={validators.required(
                    intl.formatMessage({ id: 'SignupForm.firstNameRequired' })
                  )}
                />
                <FieldTextInput
                  className={css.lastNameRoot}
                  type="text"
                  id={formId ? `${formId}.lname` : 'lname'}
                  name="lname"
                  autoComplete="family-name"
                  label={intl.formatMessage({ id: 'SignupForm.lastNameLabel' })}
                  placeholder={intl.formatMessage({ id: 'SignupForm.lastNamePlaceholder' })}
                  validate={validators.required(
                    intl.formatMessage({ id: 'SignupForm.lastNameRequired' })
                  )}
                />
              </div>
              <FieldTextInput
                className={css.password}
                type="password"
                id={formId ? `${formId}.password` : 'password'}
                name="password"
                autoComplete="new-password"
                label={intl.formatMessage({ id: 'SignupForm.passwordLabel' })}
                placeholder={intl.formatMessage({ id: 'SignupForm.passwordPlaceholder' })}
                validate={passwordValidators}
              />

              <FieldSelect
                id="heardAbout"
                name="heardAbout"
                className={css.hearAboutField}
                label={intl.formatMessage({ id: 'SignupForm.hearabout' })}
                validate={validators.required(<FormattedMessage id="SignupForm.field" />)}
              >
                <option disabled value="">
                  {intl.formatMessage({ id: 'SignupForm.select' })}
                </option>
                {hearAboutOptions.map(o => (
                  <option key={o.key} value={o.key}>
                    {intl.formatMessage({ id: o.messageID })}
                  </option>
                ))}
              </FieldSelect>
            </div>

            <div className={css.bottomWrapper}>
              {termsAndConditions}
              <PrimaryButton
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                data-elbaction="click:click registbutton"
              >
                <FormattedMessage id="SignupForm.signUp" />
              </PrimaryButton>
            </div>
          </Form>
        );
      }}
    />
  );
};

SignupFormComponent.defaultProps = { inProgress: false };

SignupFormComponent.propTypes = {
  inProgress: bool,
  termsAndConditions: node.isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
  onSubmit: Function.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
